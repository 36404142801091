<!-- 奖励 -->
<template>
  <div>
    <van-cell v-for="(item,index) in list" :key="index">
      <div class="report-list-box">
        <div class="report-box-left">
          <div class="report-left-title">
            <div>{{ $t('名称') }}: {{ item.title }} </div>
            <div>" + {{ item.number }} "</div>
            <div>{{ $t('钱包地址') }}: {{ item.from_username.slice(0, 6) + "..." + item.from_username.slice(-8) }} </div>
          </div>
        </div>
        <div class="report-box-right">{{ item.created_at }}</div>
      </div>
    </van-cell>
  </div>
</template>
<script>
  export default {
    props: {
      list: {
        type: Array,
        default: function () {
          return []
        }
      },
    },
  }
</script>
<style lang="less" scoped>
   .report-myreport {
     width: 92%;
     margin: 18px auto 5px auto;
     .report-myreport-title {
       font-family: REEJI-PinboGB-Flash;
       font-size: 24px;
       font-weight: normal;
       font-stretch: normal;
       letter-spacing: 0px;
       color: #ffffff;
     }
     .report-myreport-list {
       height: 45vh;
       background-color: #ffffff;
       border-radius: 5px;
       overflow: auto;
       padding: 0 10px;
   
       .report-list-box {
         display: flex;
   
         .report-box-left {
           flex: 1;
   
           .report-left-title {
             font-family: AeroMaticsDisplayItalic;
             font-size: 14px;
             font-weight: normal;
             font-stretch: normal;
             letter-spacing: 0px;
             color: #2b2b2b;
             font-weight: 600;
           }
           
         }
         .report-box-right {
           flex: 1;
           text-align: center;
           font-family: AeroMaticsDisplayItalic;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           letter-spacing: 0px;
           color: #2b2b2b;
           font-weight: 600;
         }
       }
   
       /deep/ .van-list {
         height: 100%;
       }
       /deep/ .van-cell {
         padding: 8px 4px;
         line-height: 1;
       }
       /deep/ .van-cell:nth-child(1) {
         padding-top: 8px;
       }
   
       /deep/ .van-cell::after {
         border: 0;
       }
     }
     .report-myreport-list::-webkit-scrollbar {
       width: 5px;
       border-radius: 5px;
       background: #fff;
     }
     .report-myreport-list::-webkit-scrollbar-track {
       border-radius: 5px;
       background: #fff;
     }
     .report-myreport-list::-webkit-scrollbar-thumb {
       border-radius: 5px;
       background: #5c65cc;
     }
   }
</style>