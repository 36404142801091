<template>
  <div class="head">
    <div class="title">
      <img :src="img"
           mode="">
      <div class="titleName" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{ title }}</div>
      <img class="navto1"
           @click="show = true"
           src="@/assets/bracelet_img/lang.png"
           alt="">
      <img class="navto"
           @click="goTo"
           src="@/assets/bracelet_img/navto.png"
           alt="">
    </div>
    <div class="blackBar">
      <div class="greenBar mar_3"></div>
      <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{ $t('一起社交赚钱') }}</span>
    </div>

    <van-popup class="pop-up"
               v-model="show">
      <div class="pop-up-box">
        <van-radio-group v-model="radio"
                         checked-color="#7c4283">
          <van-radio class="pop-box-radio"
                     name="cn">中文</van-radio>
          <van-radio class="pop-box-radio"
                     name="en">English</van-radio>
          <van-radio class="pop-box-radio"
                     name="ko">한국어</van-radio>
          <van-radio class="pop-box-radio"
                     name="vi">Vietnamese</van-radio>
          <van-radio class="pop-box-radio"
                     name="ms">Malay</van-radio>
          <van-radio class="pop-box-radio"
                     name="id">Indonesian</van-radio>
        </van-radio-group>
      </div>
      <div class="pop-up-btn">
        <button class="pop-btn-btn btn-cancel-col"
                @click="cancel">{{$t('取消')}}</button>
        <button class="pop-btn-btn btn-determine-col"
                @click="setLang">{{$t('确定')}}</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { language } from '@/api/api'
export default {
  props: {
    img: {
      type: String,
      default: function () {
        return ''
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      tabType: 'joinRoom',
      show: false,
      radio: '',
    }
  },
  methods: {
    goTo () {
      this.$router.push('/navbar')
    },
    setLang () {
      if (this.radio.length == '') {
        return
      }

      language({ lang: this.radio }).then(res => {
        if (res.code == 0) {
          localStorage.setItem('langue', this.radio)
          this.show = false
          this.$i18n.locale = this.radio
          this.radio = ''
          // this.$router.go(0)
          location.reload();
        }
      })
    },
    cancel () {
      this.show = false
      this.radio = ''
    },
  }
}
</script>

<style lang="less" scoped>
.head {
  .title {
    padding: 0 7px;
    background-color: #6480f3;
    display: flex;
    align-items: center;
    font-family: '071-SSRuiFengTi';
    font-size: 40px;
    margin-bottom: 3px;
    position: relative;
    img {
      width: 33px;
      height: 33px;
      margin-right: 6px;
    }
    .titleName{
      max-width: 70%;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow:ellipsis;
    }
  }
  .blackBar {
    background-color: #2b2b2b;
    display: flex;
    align-items: center;
    font-family: 'TsangerYuYangT-W03';
    font-size: 12px;
    word-spacing: 5px;
    .greenBar {
      width: 20px;
      height: 10px;
      background-color: #a7f264;
    }
    .mar_3 {
      margin-right: 3px;
    }
  }

  .navto {
    position: absolute;
    right: 0;
    width: 33px;
    height: 33px;
  }
  .navto1 {
    position: absolute;
    right: 50px;
    width: 33px;
    height: 33px;
  }
  .pop-up {
    color: #000;
    border-radius: 10px;
    width: 280px;

    .pop-up-box {
      padding: 10px;
      .pop-box-radio {
        padding: 5px;
      }
    }
    .pop-up-btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;
      .pop-btn-btn {
        border: 0;
        background-color: #fff;
        width: 40%;
      }
      .btn-determine-col {
        color: #ee0a24;
      }
      .btn-cancel-col {
        color: #b6b6b6;
      }
    }
  }
  
}
</style>