<!-- 收益 -->
<template>
  <div>
    <van-cell v-for="(item,index) in list"
              :class="index + 1 < list.length ? 'report-list-border':''"
              :key="index">
      <div class="report-list-box">
        <div class="report-box-left">
          <div class="report-left-title">
            <div>+ {{ item.number }} {{ item.coin_name }}</div>
            <div>"{{ item.title }}"</div>
            <div v-show="item.type == 0" :class="{'vn-italic': $i18n.locale == 'vi'}">"{{$t('卡路里')}}: {{ item?.user_sport?.calories_award ?? 0 }}"</div>
            <div v-show="item.type == 0" :class="{'vn-italic': $i18n.locale == 'vi'}">"{{$t('心率')}}: {{ item?.user_sport?.heart_rate_award ?? 0 }}"</div>
            <div v-show="item.type == 0" :class="{'vn-italic': $i18n.locale == 'vi'}">"{{$t('步数')}}: {{ item?.user_sport?.steps_award ?? 0 }}"</div>
            <div v-show="item.type == 0" :class="{'vn-italic': $i18n.locale == 'vi'}">"{{$t('时长')}}: {{ item?.user_sport?.duration_award ?? 0 }}"</div>
            <div v-show="item.type == 0">"{{$t('收藏奖励')}}: {{ item?.user_sport?.collect_award ?? 0 }}"</div>
            <div v-show="item.type == 1">"{{$t('收藏奖励')}}: {{ item?.chat_member?.collect_award ?? 0 }}"</div>
          </div>
          <div class="report-left-text">
            <!-- <div v-show="item.type == 1" class="report-left-title">{{$t('总分')}}:{{ item.total_score }}</div> -->
            <!-- <div class="report-text-1">{{$t('持续')}} - {{ item.duration }}</div> -->
            <!-- <div v-show="item.type == 1" class="report-text-1">{{$t('玩家')}} - {{ item.game_player }}</div> -->
            <!-- <div v-show="item.type == 1" class="report-text-1">{{$t('排行')}} - {{ item.ranking }}</div> -->
          </div>
        </div>
        <div class="report-box-right">
          <div>{{ item.created_at }}</div>
          <div style="color: 000;" :class="{
            'vn-italic': $i18n.locale == 'vi',
            'red': item.state == 0, 
            'grey': item.state == 1, 
            'green': item.state == 2
            }"
          >{{ state(item.state) }}</div>
        </div>
      </div>
    </van-cell>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  computed: {
    state(){
      return (type)=>{
        if(type == -1){
          return this.$t('不可领取')
        }else if(type == 0){
          return this.$t('未领取')
        }else if(type == 1){
          return this.$t('领取中')
        }else if(type == 2){
          return this.$t('领取成功')
        }else if(type == 4){
          return this.$t('已卖出')
        }else if(type == 5){
          return this.$t('已升级')
        }
      }
    }
  },
}
</script>
<style lang="less" scoped>
.report-myreport {
  width: 92%;
  margin: 18px auto 5px auto;
  .report-myreport-title {
    font-family: REEJI-PinboGB-Flash;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .report-myreport-list {
    height: 45vh;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: auto;
    padding: 0 10px;

    .report-list-border {
      border-bottom: solid 1px #6111a8;
    }
    .report-list-box {
      display: flex;

      .report-box-left {
        flex: 1;

        .report-left-title {
          font-family: AeroMaticsDisplayItalic;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #2b2b2b;
          font-weight: 600;
        }
        .report-left-text {
          margin-top: 10px;
          .report-text-1 {
            font-family: AeroMaticsDisplayItalic;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #2b2b2b;
          }
        }
      }
      .report-box-right {
        flex: 1;
        text-align: center;
        font-family: AeroMaticsDisplayItalic;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2b2b2b;
        font-weight: 600;
      }
      .red{
        color: red;
      }
      .green{
        color: green;
      }
      .grey{
        color: grey;
      }
    }

    /deep/ .van-list {
      height: 100%;
    }
    /deep/ .van-cell {
      padding: 13px 4px;
      line-height: 1;
    }
    /deep/ .van-cell:nth-child(1) {
      padding-top: 8px;
    }

    /deep/ .van-cell::after {
      border: 0;
    }
  }
  .report-myreport-list::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background: #fff;
  }
  .report-myreport-list::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #fff;
  }
  .report-myreport-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #5c65cc;
  }
}
</style>