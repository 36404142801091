<template>
    <div class="watchFoot">
        <div class="btm">
            <div class="left" @click="goback">
                <img src="@/assets/bracelet_img/46.png" alt="">
            </div>
            <div class="right">
                <span class="mar_ri_11">M</span>
                <span class="mar_ri_11">E</span>
                <span class="mar_ri_11">T</span>
                <span class="mar_ri_11">A</span>
                <span class="mar_ri_11">N</span>
                <span class="mar_ri_11">E</span>
                <span class="mar_ri_11">B</span>
                <span class="mar_ri_11">U</span>
                <span class="mar_ri_11">L</span>
                <span class="mar_ri_11">A</span>
                <span>S</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        /* 返回上一页 */
        goback () {
            this.$router.go(-1)
        },
    },
}
</script>
<style lang="less" scoped>
    .watchFoot{
        .btm{
            background-color: rgba(43, 43, 43, 0.5);
            border-radius: 0px 25px 5px 0px;
            display: flex;
            align-items: center;
            position: fixed;
            z-index: 10;
            left: 0;
            bottom: 10px;
            padding: 3px 33px 3px 0;
            .left{
                background-color: rgba(43, 43, 43, 0.9);
                border-radius: 0 3px 3px 0;
                border: 2px solid #a7f264;
                border-left: none;
                padding: 6px 15px;
                display: flex;
                margin-right: 14px;
                img{
                    width: 25px;
	                height: 19px;
                }
            }
            .right{
                font-family: 'KenyanCoffeeRg-BoldItalic';
                font-size: 30px;
                color: #ffffff;
                .mar_ri_11{
                    margin-right: 11px;
                }
            }
        }
    }
@media screen and (min-width: 480px){
    //在这里写非小屏幕设备的样式  -- 非手机
    .watchFoot{
        .btm{
            position: fixed;
            left: calc(50% - 215px);
            bottom: 10px;
        }
    }
}
</style>