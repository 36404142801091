<template>
  <div class="market">
    <watch-head :title="$t('报告')"
                :img="require('@/assets/bracelet_img/report/report.png')"></watch-head>

    <div class="report-tabs">
      <div class="report-tabs-list"
           :class="{ 'report-tabs-currentList':tabsCurrent == index, 'vn-bold-italic': $i18n.locale == 'vi'}"
           v-for="(list, index) in reportTabs"
           :key="index"
           @click="tabsToggle(list, index)">{{list.tabs}}</div>
    </div>
    <div class="report-filter">
      <div class="report-filter-time">
        <div @click="timeConfirm"
             class="report-time-img"><img src="@/assets/bracelet_img/report/filter.png"> </div>
        <div class="report-time-choose">
          <div class="report-choose-start">
            <van-field v-model="startDate"
                       :class="{'vn-italic': $i18n.locale == 'vi'}"
                       label-width="0"
                       readonly
                       :placeholder="$t('开始时间')" />
            <van-calendar style="color:#2b2b2b;"
                          :class="{'vn-italic': $i18n.locale == 'vi'}"
                          color="#6480f3"
                          :min-date="minDate"
                          v-model="startTimeShow"
                          @confirm="onStartTime"
                          :confirm-text="$t('确定')"
                          :title="$t('日期选择')"
                          :show-subtitle="false" />
            <img src="@/assets/bracelet_img/44.png"
                 @click="startTimeShow = true">
          </div>
          <div class="report-choose-zhi">{{$t('至')}}</div>
          <div class="report-choose-start">
            <van-field v-model="endDate"
                       :class="{'vn-italic': $i18n.locale == 'vi'}"
                       label-width="0"
                       readonly
                       :placeholder="$t('结束时间')" />
            <van-calendar style="color:#2b2b2b;"
                          color="#6480f3"
                          :class="{'vn-italic': $i18n.locale == 'vi'}"
                          :min-date="minDate"
                          v-model="endTimeShow"
                          :confirm-text="$t('确定')"
                          :title="$t('日期选择')"
                          :show-subtitle="false"
                          @confirm="onEndTime" />
            <img src="@/assets/bracelet_img/44.png"
                 @click="endTimeShow = true">
          </div>
        </div>
      </div>
      <div class="report-myreport">
        <div class="report-myreport-title"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{title}}</div>
        <!-- 运动收益 -->
        <div v-show="tabsCurrent === 0"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('运动收益')}}：{{ sport_income.number }} {{ sport_income.coin }}</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="sportsLoading"
                      @click="incomeReceive('sport')">{{$t('领取')}}</van-button>
        </div>
        <!-- 运动收益 bitney -->
        <!-- <div v-show="tabsCurrent === 0"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('运动收益')}}：{{ sport_income_bitney.number }} {{ sport_income_bitney.coin }}</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="sportsBitneyLoading"
                      @click="incomeReceive('sport_income_bitney')">{{$t('领取')}}</van-button>
        </div> -->
        <!-- 社交收益 -->
        <div v-show="tabsCurrent === 0"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('社交收益')}}：{{ chat_income.number }} {{ chat_income.coin }}</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="socialityLoading"
                      @click="incomeReceive('chat')">{{$t('领取')}}</van-button>
        </div>
        <!-- 领取团队收益 -->
        <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('团队收益')}}：{{ team_buy_nft_income.number }} {{ team_buy_nft_income.coin }}</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="teamLoading"
                      @click="incomeReceive('team_buy')">{{$t('领取')}}</van-button>
        </div>
        <!-- 团队NFT升级奖励 bitney -->
        <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('团队NFT升级奖励')}}：{{ team_level_nft_income.number }} {{ team_level_nft_income.coin }}</span>
          <van-button class="btn"
                      type="default"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="gradeTeamAwardLoading"
                      @click="incomeReceive('team_level_nft')">{{$t('领取')}}</van-button>
        </div>
        <!-- 挂卖补偿奖励 -->
        <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('挂卖补偿奖励')}}：{{ nft_sale_compensate.number }} {{ nft_sale_compensate.coin }}</span>
          <van-button class="btn"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="sellMakeLoading"
                      @click="incomeReceive('sale_compensate')">{{$t('领取')}}</van-button>
        </div>
        <!-- 挂卖补偿奖励 bitney -->
        <!-- <div v-show="tabsCurrent === 1"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('挂卖补偿奖励')}}：{{ nft_sale_compensate_bitney.number }} {{ nft_sale_compensate_bitney.coin }}</span>
          <van-button class="btn"
                      :class="{'vn-italic': $i18n.locale == 'vi'}"
                      :loading="sellMakeBitneyLoading"
                      @click="incomeReceive('sale_compensate_bitney')">{{$t('领取')}}</van-button>
        </div> -->
        <!-- 领取升级的nft -->
        <div v-show="tabsCurrent === 2"
             class="profit">
          <span :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('已升级的nft数量')}}：{{ level_nft_num }}</span>
        </div>
        <div class="report-myreport-list">
          <van-list v-model="loading"
                    :class="{'vn-italic': $i18n.locale == 'vi'}"
                    :finished="finished"
                    :immediate-check="false"
                    :offset="20"
                    :finished-text="$t('没有更多了')"
                    @load="onLoad">
            <earnings v-if="tabsCurrent === 0"
                      :list="list"></earnings>
            <rewards v-if="tabsCurrent === 1"
                     :list="list"></rewards>
            <nft v-if="tabsCurrent === 2"
                 :list="list"></nft>
          </van-list>
        </div>
      </div>
    </div>
    <watch-foot></watch-foot>
  </div>
</template>
   <script>
import watchHead from '@/components/watchHead'
import watchFoot from '@/components/watchFoot/index.vue'
import earnings from './components/earnings'
import rewards from './components/rewards'
import nft from './components/nft'
import { getUpgradeList, getIncomeReceiveNumber, incomeReceive } from '@/api/api'
import { mapState } from 'vuex'
export default {
  components: {
    watchHead,
    watchFoot,
    earnings,
    rewards,
    nft,
  },
  data () {
    return {
      sportsLoading: false,
     //  sportsBitneyLoading: false,
      socialityLoading: false,
      reportTabs: [
        { tabs: this.$t('收益'), name: this.$t('我的收益报告') + ' ：' },
        { tabs: this.$t('奖励'), name: this.$t('我的奖励报告') + ' ：' },
        { tabs: 'NFT', name: this.$t('我的NFT报告') + ' ：' },
      ],
      searchValue: '',
      tabsCurrent: 0,
      title: this.$t('我的收益报告') + ' ：',
      startDate: '',
      startTime: null,
      endDate: '',
      endTime: null,
      startTimeShow: false,
      endTimeShow: false,
      minDate: new Date(2010, 0, 1),
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      timer: null,
      teamLoading: false,
      gradeTeamAwardLoading: false,
      sellMakeLoading: false,
     //  sellMakeBitneyLoading:false,
      sport_income: { // 运动收益
        type: null,
        number: 0,
        coin: '',
        coin_type:2
      },
      sport_income_bitney: { // 运动收益 bitney
        type: null,
        number: 0,
        coin: '',
        coin_type:1
      },
      chat_income: { // 社交收益
        type: null,
        number: 0,
        coin: '',
      },
      team_buy_nft_income: { // 购买nft团队奖励
        type: null,
        number: 0,
        coin: '',
      },
      team_level_nft_income: { // 团队NFT升级奖励
        type: null,
        number: 0,
        coin: '',
      },
      nft_sale_compensate: { // 挂卖补偿奖励
        type: null,
        number: 0,
        coin: '',
      },
      nft_sale_compensate_bitney: { // 挂卖补偿奖励 bitney
        type: null,
        number: 0,
        coin: '',
        coin_type:1
      },
      level_nft_num: 0, // NFT已升级次数
    }
  },
  computed: {
    ...mapState(['web3', 'withdrawContract']),
  },
  created () {
    this.waitForGlobal()
  },
  methods: {
    // 领取收益
    incomeReceive (rewardType) {
      let type = null
      let coin_type = null
      if (rewardType == 'sport') { // 运动收益
        if (this.sport_income.number == 0) {
          this.$toast('暂无收益可领取');
          return
        }
        type = this.sport_income.type
        coin_type = this.sport_income.coin_type
        this.sportsLoading = true
      } else if( rewardType== 'sport_income_bitney'){
          if (this.sport_income_bitney.number == 0) { // 运动收益 bitney
          this.$toast('暂无收益可领取');
          return
        }
        type = this.sport_income_bitney.type
        coin_type = this.sport_income_bitney.coin_type
        this.sportsBitneyLoading = true

      } else if (rewardType == 'chat') { // 社交收益
        if (this.chat_income.number == 0) {
          this.$toast('暂无收益可领取');
          return
        }
        type = this.chat_income.type
        this.socialityLoading = true
      } else if (rewardType == 'team_buy') { // 团队收益
        if (this.team_buy_nft_income.number == 0) {
          this.$toast('暂无收益可领取');
          return
        }
        type = this.team_buy_nft_income.type
        this.teamLoading = true
      } else if (rewardType == 'team_level_nft') { // 团队NFT升级奖励
        if (this.team_level_nft_income.number == 0) {
          this.$toast('暂无收益可领取');
          return
        }
        type = this.team_level_nft_income.type
        this.gradeTeamAwardLoading = true
      } else if (rewardType == 'sale_compensate') { // 挂卖补偿奖励
        if (this.nft_sale_compensate.number == 0) {
          this.$toast('暂无收益可领取');
          return
        }
        type = this.nft_sale_compensate.type
        this.sellMakeLoading = true
      }else if (rewardType == 'sale_compensate_bitney') { // 挂卖补偿奖励 bitney
        if (this.nft_sale_compensate_bitney.number == 0) {
          this.$toast('暂无收益可领取');
          return
        }
        type = this.nft_sale_compensate_bitney.type
        coin_type = this.nft_sale_compensate_bitney.coin_type
        this.sellMakeBitneyLoading = true
      }

      incomeReceive({ type, coin_type }).then(res => {
        if (res.code == 0) {
          let toast = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t('领取中...'),
          });
          let token_amount = new this.$Bignumber(res.data.token_amount);
          this.withdrawContract.methods.withdraw(
            res.data.token,
            this.web3.utils.fromDecimal(token_amount),
            res.data._deadline,
            res.data.oid,
            res.data.v,
            res.data.r,
            res.data.s,
          ).send({ from: localStorage.getItem('defaultAddress') }).then(() => {
            toast.clear();
            this.$toast(this.$t('成功'));
            this.withdrawEnd(rewardType);
            if (!this.loading) {
              this.page = 1
              this.loading = true
              this.finished = false
              this.list = []
              this.searchValue = ''
              this.onLoad()
            }
          }).catch(() => {
            toast.clear();
            this.$toast(this.$t('失败'));
            this.withdrawEnd(rewardType);
          })
        } else {
          this.$toast(res.msg);
          this.withdrawEnd(rewardType);
        }
      })
    },
    // 领取结束 取消按钮Loading 
    withdrawEnd (rewardType) {
      if (rewardType == 'sport') { // 运动收益
        this.sportsLoading = false
      } else if(rewardType == 'sport_income_bitney'){
          this.sportsBitneyLoading = false
      } else if (rewardType == 'chat') { // 社交收益
        this.socialityLoading = false
      } else if (rewardType == 'team_buy') { // 团队收益
        this.teamLoading = false
      } else if (rewardType == 'team_level_nft') { // 团队NFT升级奖励
        this.gradeTeamAwardLoading = false
      } else if (rewardType == 'sale_compensate') { // 挂卖补偿奖励
        this.sellMakeLoading = false
      }else if(rewardType == 'sale_compensate_bitney'){
          this.sellMakeBitneyLoading = false
      }
      this.getIncomeReceiveNumber();
      this.getSellMakeUpNumber();
    },
    // 获取可领取收益数量
    getIncomeReceiveNumber () {
      getIncomeReceiveNumber({ tab: this.tabsCurrent }).then(res => {
        if (res.code == 0) {
          if (this.tabsCurrent == 0) { // 收益
            this.sport_income = res.data.sport_income
            this.sport_income_bitney = res.data.sport_income_bitney // 运动收益 bitney
            this.chat_income = res.data.chat_income
          } else if (this.tabsCurrent == 1) { // 奖励
            this.team_buy_nft_income = res.data.team_buy_nft_income
            this.team_level_nft_income = res.data.team_level_nft_income
          } else if (this.tabsCurrent == 2) { // 我的NFT报告
            this.level_nft_num = res.data.level_nft_num
          }
        }
      })
    },
    // 获取挂卖补偿数量
    getSellMakeUpNumber () {
      getIncomeReceiveNumber({ tab: 3 }).then(res => {
        if (res.code == 0) {
          // 挂卖补偿奖励
          this.nft_sale_compensate = res.data.nft_sale_compensate
          this.nft_sale_compensate_bitney = res.data.nft_sale_compensate_bitney
        }
      })
    },
    /* tabs切换 */
    tabsToggle (val, index) {
      if (this.tabsCurrent !== index) {
        if (!this.loading) {
          this.tabsCurrent = index
          this.title = val.name
          this.page = 1
          this.loading = true
          this.finished = false
          this.list = []
          // this.startDate = ''
          // this.endDate = ''
          this.searchValue = ''
          this.onLoad()
          this.getIncomeReceiveNumber()
        }
      }
    },
    timeConfirm () {
      if (!this.loading) {
        this.page = 1
        this.loading = true
        this.finished = false
        this.list = []
        this.searchValue = ''
        this.onLoad()
      }
    },
    /* 时间格式化 */
    formatDate (date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      return `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
    },
    /* 开始时间 */
    onStartTime (date) {
      if (this.endTime) {
        if (this.endTime < date.getTime()) {
          this.$toast(this.$t('开始时间要小于结束时间'));
          return;
        }
      }
      this.startTime = date.getTime()
      this.startDate = this.formatDate(date);
      this.startTimeShow = false;
    },
    /* 结束时间 */
    onEndTime (date) {
      if (this.startTime) {
        if (this.startTime > date.getTime()) {
          this.$toast(this.$t('结束时间要大于开始时间'));
          return;
        }
      }
      this.endTime = date.getTime()
      this.endDate = this.formatDate(date);
      this.endTimeShow = false;
    },
    /* 报告列表 */
    onLoad () {
      getUpgradeList({
        tab: this.tabsCurrent,
        start: this.startDate,
        end: this.endDate,
        page: this.page,
        limit: this.limit,
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data.list)
          this.loading = false;
          if (res.data.list.length < this.limit) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.tabsCurrent = 0
        this.title = this.$t('我的收益报告') + ' ：'
        this.page = 1
        this.loading = true
        this.finished = false
        this.list = []
        this.startDate = ''
        this.endDate = ''
        this.searchValue = ''
        this.onLoad();
        this.getIncomeReceiveNumber();
        this.getSellMakeUpNumber();
        //    this.web3.eth.getBlockNumber((error, result) => {
        //      console.log(error, result, '--------------------')
        //    })
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  },
}
   </script>
   <style lang="less" scoped>
.market {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;

  .report-tabs {
    margin: 19px 0 16px 0;
    background-color: rgba(43, 43, 43, 0.5);
    width: 100%;
    padding: 11px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .report-tabs-list {
      font-family: REEJI-PinboGB-Flash;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #5c65cb;
      cursor: pointer;
    }
    .report-tabs-currentList {
      color: #a7f264;
    }
  }
  .report-filter {
    .report-filter-search {
      width: 92%;
      background-color: #ffffff;
      border-radius: 5px;
      margin: 0 auto;
      color: #2b2b2b;
      display: flex;
      align-items: center;
      padding: 10px;
      .report-search-ipt {
        flex: 1;
        height: 100%;
        input {
          width: 95%;
          height: 100%;
          font-size: 25px;
          border: 0;
        }
      }
      .report-search-img {
        width: 26px;
        height: 26px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .report-filter-time {
      // width: 100%;
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 19px 0px 0px 19px;
      margin-top: 4px;
      margin-left: 2%;
      padding: 4px;
      display: flex;
      align-items: center;

      .report-time-img {
        width: 31px;
        min-width: 31px;
        height: 31px;
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .report-time-choose {
        width: 100%;
        margin-left: 1%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .report-choose-start {
          width: 138px;
          padding: 5px 8px 3px 20px;
          background-color: #ffffff;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 15px;
            height: 15px;
            margin-bottom: 2px;
          }
        }
        .report-choose-zhi {
          font-family: REEJI-PinboGB-Flash;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          // margin: 0 12px;
        }
      }
    }
    .report-myreport {
      width: 92%;
      margin: 18px auto 5px auto;
      .report-myreport-title {
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        font-family: REEJI-PinboGB-Flash;
        font-size: 24px;
      }
      .profit {
        font-family: REEJI-PinboGB-Flash;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 5px;
        .btn {
          height: 25px;
          line-height: 25px;
          background: #a7f264;
          color: #000;
          border: none;
        }
      }
      .report-myreport-list {
        height: 45vh;
        background-color: #ffffff;
        border-radius: 5px;
        overflow: auto;
        padding: 0 10px;

        .report-list-border {
          border-bottom: solid 1px #6111a8;
        }
        .report-list-box {
          display: flex;

          .report-box-left {
            flex: 1;

            .report-left-title {
              font-family: AeroMaticsDisplayItalic;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #2b2b2b;
            }
            .report-left-text {
              margin-top: 10px;
              .report-text-1 {
                font-family: AeroMaticsDisplayItalic;
                font-size: 7px;
                font-weight: normal;
                letter-spacing: 0px;
                color: #2b2b2b;
              }
            }
          }
          .report-box-right {
            flex: 1;
            text-align: center;
            font-family: AeroMaticsDisplayItalic;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #2b2b2b;
          }
        }

        /deep/ .van-list {
          height: 100%;
        }
        /deep/ .van-cell {
          padding: 13px 4px;
          line-height: 1;
        }
        /deep/ .van-cell:nth-child(1) {
          padding-top: 8px;
        }

        /deep/ .van-cell::after {
          border: 0;
        }
      }
      .report-myreport-list::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
        background: #fff;
      }
      .report-myreport-list::-webkit-scrollbar-track {
        border-radius: 5px;
        background: #fff;
      }
      .report-myreport-list::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #5c65cc;
      }
    }
  }
}
/deep/ .van-cell__value--alone {
  font-family: 'AeroMaticsDisplayItalic';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #2b2b2b;
}
/deep/ .van-cell {
  padding: 0;
}
</style>